import React from 'react'
import { Alert, Button, Modal, ModalBody, ModalHeader,FormInput, Form, FormGroup, Row, Col, InputGroup, InputGroupAddon, InputGroupText } from "shards-react";

class CreateFamilyForm extends React.Component{
    constructor(props){
        super()
        let r = this.makeid(6)
        this.handleInputChange=this.handleInputChange.bind(this)
        this.state={
            fields:{
                familycode:r,
                familyname:""
            },
            errors:{}
        }
        this.toggleCreateFamilyForm=props.toggle
        this.toggleCreateFamilyForm=this.toggleCreateFamilyForm.bind(this)
        this.createFamily=props.createFamily
        this.createFamily=this.createFamily.bind(this)
    }
    componentDidMount(){
        
    }

    makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * 
     charactersLength));
       }
       return result;
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Name
        if (!fields["familyname"]) {
            formIsValid = false;
            errors["familyname"] = "Veuillez renseigner un nom de famille";
        }

        if (fields["familyname"].length>255) {
            formIsValid = false;
            errors["familyname"] = "Le nom de famille n'est pas valide";
        }

        this.setState({errors: errors})
        return formIsValid;
    }

    handleInputChange(event){
        const eventtargetvalue = event.target.value
        const name = event.target.name
        this.setState(prevState => {
            return {fields: {...prevState.fields, [name]: eventtargetvalue}}
        })
    }

    postFamilyForm(){
        if (this.handleValidation()){
            this.createFamily(this.state.fields.familycode, this.state.fields.familyname);
            this.props.toggle()
        } else{

        }
    }

    render(){
        const { open } = this.props;
        return (
            <Modal open={open} toggle={this.props.toggle} backdropClassName="modalBackdrop">
                <ModalHeader>Créer une nouvelle famille</ModalHeader>
                <ModalBody>
                    {this.state.errors[Object.keys(this.state.errors)[0]] &&<Alert theme="danger">{this.state.errors[Object.keys(this.state.errors)[0]]}</Alert>}
                   <Row>
                        <div className="p-3 mt-3 border rounded">
                            <h5>Attention <span role="img" aria-label="attention">💡</span></h5>
                            <span>
                                Note bien le code ci-dessous, il est nécessaire pour inviter des participants dans la famille.
                            </span>
                        </div>
                    </Row>
                    <Row>
                        <Form>
                            <FormGroup>
                                <InputGroup className="mb-2">
                                    <InputGroupAddon type="prepend">
                                        <InputGroupText>Nom de la famille</InputGroupText>
                                    </InputGroupAddon>
                                    <FormInput name="familyname" placeholder="Nom de la famille" invalid={!this.state.errors["familyname"]===false} value={this.state.fields.familyname} onChange={this.handleInputChange}/> 
                                </InputGroup>
                            </FormGroup>
                            <FormInput disabled={true} name="familycode" placeholder="code" value={this.state.fields.familycode}/> 
                            <Row>
                                <Col><Button theme="success" onClick={()=>{this.postFamilyForm()}}> Valider </Button></Col>
                            </Row>
                    </Form>
                    </Row>
                </ModalBody>
            </Modal>
        );
    };
}

export default CreateFamilyForm