import React, {useState} from 'react';
import { createBrowserHistory } from 'history';
import './App.css';
import Wishlist from './WishList';
import Appalert from './Appalert';
import Families from './Families';
import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css";
import {Gift} from 'grommet-icons'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import {
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Collapse
} from "shards-react";
import LoginForm from './LoginForm';

const main = {
  "marginTop": '70px'
};


class Home extends React.Component {
  constructor(){
    super()
    this.state={
      activeMenuItem:0,
      authenticated:false,
      dropdownOpen: false,
      collapseOpen: false,
      userID:"",
      users:[],
      currentAlert:{
        visible:false,
        message:"",
        theme:"success",
        countdown: 0,
        timeUntilDismissed: 5
      }
    }
    //this.showAlert("etst","success")
    this.showAlert = this.showAlert.bind(this)
    this.nav = this.nav.bind(this)
    this.refreshUser = this.refreshUser.bind(this)
    this.dismiss = this.dismiss.bind(this)
    this.toggleNavbar = this.toggleNavbar.bind(this)
    this.handleTimeChange = this.handleTimeChange.bind(this)
    this.clearInterval = this.clearInterval.bind(this)
  }

  async componentDidMount() {
    await fetch('/api/users/me')
    .then(res => res.json())
    .then(data => {
        if(!data.message)
          this.setState({authenticated:true})
    })
    .catch(error=>{
      this.setState({authenticated:false})
    })
  }

  showAlert(message,theme){
    this.clearInterval()
    this.setState({
      currentAlert:{
        message:message,
        theme:theme,
        visible:true,
        countdown: 0, 
        timeUntilDismissed: 5
      }
    })
    this.interval = setInterval(this.handleTimeChange, 1000);
  }

  dismiss() {
    this.setState(prevState=>{ 
      return {
        currentAlert:{
          ...prevState.currentAlert,
          visible: false 
        }
      }
    });
  }

  handleTimeChange() {
    if (this.state.currentAlert.countdown < this.state.currentAlert.timeUntilDismissed - 1) {
      this.setState(prevState=>{
        return{
          ...this.state,
          currentAlert:{
            ...prevState.currentAlert,
            countdown: this.state.currentAlert.countdown + 1
          }
        }
      });
      return;
    }

    this.setState(prevState=>{ 
      return {
        ...this.state, 
        currentAlert:{
          ...prevState.currentAlert,
          visible: false 
        }
      }

      
    });
    this.clearInterval();
  }

  clearInterval() {
    clearInterval(this.interval);
    this.interval = null;
  }

  refreshUser(authenticated,currentUser){
    this.setState({
      authenticated:authenticated
      //userID:userID,
      //currentUser:currentUser
    })
  }

  nav(menuItemID,userID) {
    console.log("navigation")
    this.setState({
      ...this.state,
      ...{
        activeMenuItem:menuItemID,
        userID:userID,
        collapseOpen: false
      }
    })
  }

  toggleNavbar() {
    this.setState({
      ...this.state,
      ...{
        collapseOpen: !this.state.collapseOpen
      }
    });
  }

  
    
  render(){
    const browserHistory = createBrowserHistory();
  

    return (
        <Container>
            <Navbar type="dark" theme="primary" fixed="top" expand="md">
            <NavbarBrand href="#"><Gift color="white"/></NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} />
            <Collapse open={this.state.collapseOpen} navbar>
              <Nav navbar>
                <NavItem>
                  <NavLink active={this.state.activeMenuItem===0 } href="#">
                    <div onClick={()=>{this.nav(0,this.state.currentUser)}} >Ma liste</div>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink active={this.state.activeMenuItem===2} href="#">
                    <div onClick={()=>{this.nav(2)}} >Famille</div>
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>

          {this.state.authenticated &&
            <Container fluid style={main}>
              <Appalert alert={this.state.currentAlert} dismiss={this.dismiss}></Appalert>
              <Container>
                {this.state.activeMenuItem === 2 &&
                <Families
                  user={"me"}
                  nav={this.nav}
                  showAlert={this.showAlert}
                />}

                {this.state.activeMenuItem === 0 &&
                <Wishlist 
                    user={"me"}
                    showAlert={this.showAlert}
                />}
                {this.state.activeMenuItem === 1 &&
                <Wishlist 
                    user={this.state.userID}
                    //currentUser={this.state.currentUser}
                    showAlert={this.showAlert}
                />}
              </Container>
            </Container>
          }
          {!this.state.authenticated &&
            <LoginForm refreshuser={this.refreshUser}></LoginForm>
          }
        </Container>
            


         
    );
  }
}

export default Home;
