function signin(login,password){
    const q = "/api/login"
    return fetch(q,{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            login:login,
            password:password
        })
    })
}

function checkAuth(){
    return localStorage.getItem('user')
}

function register(username,password,email){
    const q = "/api/register"
    return fetch(q,{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            name:username,
            password:password,
            email:email
        })
    })
}

function getSelf(){
    return fetch('/api/users/me')
}

function getAll(){
    return fetch('/api/users')
}

function sendResetPassword(email){
    return fetch(`/api/reset/${email}`)
}

function changePasswordFromHash(userId, hash, newPass){
    const q = `/api/reset/${userId}/${hash}`
    return fetch(q,{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            password:newPass
        })
    })
}

export default {
    signin,
    register,
    getAll,
    getSelf,
    checkAuth,
    sendResetPassword, 
    changePasswordFromHash
};