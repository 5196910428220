import React from 'react';
import {Card, CardBody, Button} from "shards-react";

function User(props){
    return (

        <Card small className="mb-4 pt-3 mx-2">
            <CardBody className="text-center">
                <div className="mb-3 mx-auto">
                    <img
                    className="rounded-circle"
                    src={props.img}
                    alt={props.name}
                    width="110"
                    />
                </div>
                <h4 className="mb-0">{props.name}</h4>
                <Button pill outline onClick={()=>props.handleClick(1,props.id)}>Voir sa liste de Noël <span role="img" aria-label="liste">📩</span></Button>   
            </CardBody> 
        </Card>         
                        
                    
                    
                    

    )
}

export default User