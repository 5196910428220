import React from 'react'
import Wish from './Wish'
import WishForm from './WishForm'
import Errors from './Errors'
import {Button,Col, Container, Modal, Row } from 'shards-react'
import {Spinner} from "grommet"


class Whishlist extends React.Component{
    constructor(props){
        super()
        this.state={
            wishes:[],
            username:"",
            wishlist:{},
            isLoading:true,
            showModal:false,
            collapse:true,
            title:""
        }
        this.showAlert=props.showAlert
        this.postwish=this.postwish.bind(this)
        this.getWishDetails=this.getWishDetails.bind(this)
        this.putwish=this.putwish.bind(this)
        this.deletewish=this.deletewish.bind(this)
        this.toggle=this.toggle.bind(this)
        this.self=props.user==="me"
    }
    
    componentDidMount(){
        var user = this.props.user
        fetch('/api/users/'+ user + '/wishlists')
            .then((response) => response.json())
            .then((responseJson) => {
                this.setState({
                    isLoading: false,
                    wishes: responseJson[0].Wishes,
                    wishlist: responseJson[0],
                    username: responseJson[0].User.name,
                    title: this.self? "Ma liste de cadeaux":"Liste de "+ responseJson[0].User.name
                }, function(){});
            })
            .catch((error) =>{
                console.error(error);
            });
    }

    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }

    getWishDetails = async(url) =>{
        const q = "/api/wishes/findURL"
        const details = await fetch(q,{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               url: url
            })
        })
        .then(response => response.json())
        .catch((error) =>{
            console.error(error);
        })

        return details
    }

    postwish(newwish){
        const q = '/api/wishlists/'+this.state.wishlist.uid+'/wishes'
        fetch(q, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(newwish)
        })
        .then((response) => {
            if(!response.ok){
                throw Error(response.statusText);
            }
            return response
        })
        .then(response=>response.json())
        .then(data => {
            this.setState(prevState=>{ 
                this.showAlert("Cadeau ajouté","success")
                console.log(data)
                return prevState.wishes.push(data) 
            })
        })
        .catch(err=>
            this.showAlert(err.message,"danger")
        )
    }

    putwish(newwish,wishId){
        this.setState({isLoading: true})
        fetch('/api/wishes/'+wishId, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(newwish)
        })
        .then((response) => {
            if(!response.ok){
                throw Error(response.statusText);
            }
            return response
        })
        .then(response=>response.json())
        .then(data => {
            this.setState(prevState=>{ 
                this.showAlert("Cadeau modifié","success")
                let newWishes = prevState.wishes
                for (var i = 0; i < newWishes.length; ++i) {
                    if (newWishes[i]['uid'] === wishId) {
                        newWishes[i] = data;
                    }
                }
                return {
                    ...prevState,
                    wishes : newWishes,
                    isLoading:false
                }
            })
        })
        .catch(err=>
            this.showAlert(err.message,"danger")
        )
    }

    deletewish(wishId){
        this.setState({isLoading: true})
        fetch('/api/wishes/'+wishId, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            if(!response.ok){
                throw Error(response.statusText);
            }
            return response
        })
        .then(response=>response.json())
        .then(data => {
            this.setState(prevState=>{ 
                this.showAlert("Cadeau supprimé","success")
                let newWishes = prevState.wishes
                for (var i = 0; i < newWishes.length; i++) {
                    if (newWishes[i]['uid'] === wishId) {
                        newWishes.splice(i,1)
                    }
                }
                return {
                    ...prevState,
                    wishes:newWishes,
                    isLoading:false
                }
            })
        })
        .catch(err=>
            this.showAlert(err.message,"danger")
        )
    }

    render(){
        const wisharray = this.state.wishes
        .sort((item1,item2) => item2.importance - item1.importance)
        .map(item => <Wish getWishDetails={this.getWishDetails} putwish={this.putwish} deletewish={this.deletewish} key={item.uid} name={item.name} importance={item.importance} isSecret={item.isSecret} isPurchased={item.isPurchased} self={this.self} comment={item.comment} img={item.img} handleChange={this.handleChange} createdAt ={item.createdAt} url={item.url} id={item.uid}/>)
        
        return(
            <Container fluid>
                <Row className="page-header py-4">
                    <Col><h1>{this.state.title}</h1></Col>
                    <div>
                        <Button disabled={!this.state.collapse} size="lg" onClick={this.toggle}>
                                {this.self===false && "👻"}Ajouter un cadeau {!this.self && "secret "}
                        </Button>
                    </div>
                </Row>
                
                <Modal backdropClassName="modalBackdrop" open={!this.state.collapse}>
                    <WishForm getWishDetails={this.getWishDetails} empty={this.state.emptyform} toggle={this.toggle} handleClick={this.postwish} self={this.self===true}></WishForm>
                </Modal>
                
                <Row>
                    {this.state.isLoading === true &&
                        <div><Spinner/><span><br/>Chargement des cadeaux...</span></div>
                    }
                    <Container>
                        <Row>{wisharray}</Row>
                        {(wisharray.length===0 && this.state.isLoading === false) &&
                        <Errors></Errors>
                    }
                    </Container>
                    
                </Row>
            </Container>           
        )
    }
}

export default Whishlist