import React from 'react'
import { FormCheckbox } from "shards-react";
import { 
    Badge, 
    Button,
    Card,
    CardHeader,
    CardSubtitle,
    CardTitle,
    CardImg,
    CardBody,
    CardFooter,
    CardImgOverlay,
    Col,
    Container,
    Modal,
    Row
 } from "shards-react";
import Rating from '@material-ui/lab/Rating';
import {Edit,Link} from "grommet-icons";
import './App.css';
import WishForm from './WishForm';


class Wish extends React.Component{
    constructor(props){
        super()
        this.state={
            isLoading:true,
            collapse:true,
            edit:false, 
        }
        this.toggle=this.toggle.bind(this)
        this.editWish=this.editWish.bind(this)
        this.togglePurchased=this.togglePurchased.bind(this)
    }

    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }

    editWish() {
        this.setState({ edit: !this.state.edit });
    }

    togglePurchased(){
        this.props.putwish(
        {
            "isPurchased": !this.props.isPurchased
        },this.props.id)
    }

    render(){
        const date = new Date(this.props.createdAt);
        return (
            <Card className="mb-4 mx-2">
                <CardHeader>
                    <Container>
                        <CardTitle tag="h3">{this.props.name}</CardTitle>
                        <CardSubtitle><br></br>
                        {this.props.isSecret && <Badge outline pill theme="secondary"><span role="img" aria-label="secret">👻</span>secret </Badge>
                        }</CardSubtitle> 
                    </Container>
                </CardHeader>
                <CardBody>
                    <Row>    
                        {this.props.img &&
                            <Col>
                                <CardImg className="imgCard" src={this.props.img}/>
                            </Col>
                        }
                        <Col>    
                        <Container>
                            <Rating value={this.props.importance} readOnly />
                            <p>{this.props.comment}</p>
                            {this.props.url &&
                                <div>{this.props.url && <a target="_blank" rel="noopener noreferrer" href={this.props.url}><Link size="small"/> Voir le site </a>}</div>
                            }
                            <Badge outline pill theme="secondary">Ajouté le {new Intl.DateTimeFormat('fr-fr').format(date)}</Badge>
                        </Container>
                        </Col>
                    </Row>
                </CardBody>
                {(this.props.self===false && this.props.isPurchased) &&
                    <CardImgOverlay className="imgOverlay">
                        <p>Cadeau acheté ! <span role="img" aria-label="joie">🎉</span></p>
                    </CardImgOverlay>
                }   
                <CardFooter>
                        <Container fluid>
                        <Row>
                            {this.props.self===false &&
                            <FormCheckbox
                                toggle
                                checked={this.props.isPurchased}
                                onChange={()=>this.togglePurchased()}>
                                Acheté
                            </FormCheckbox>
                            }
                        </Row>
                        <Row>
                        {(this.props.isSecret || this.props.self) && 
                        <Button outline theme="secondary" onClick={this.editWish}><Edit/></Button>
                        }
                        </Row>
                        </Container>
                </CardFooter>
                {this.state.edit && 
                <Modal open={this.state.edit} backdropClassName="modalBackdrop" >
                    <WishForm editForm deletewish={this.props.deletewish} putwish={this.props.putwish} getWishDetails={this.props.getWishDetails} toggle={this.editWish} wish={this.props} open={this.state.edit}></WishForm>
                </Modal>
                }
            </Card> 
        )
    } 
}
export default Wish