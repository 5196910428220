import React from 'react'
import { Alert, Button, Modal, ModalBody, ModalHeader,FormInput, Form, Row, Col, InputGroup, InputGroupAddon, InputGroupText } from "shards-react";

class JoinFamilyForm extends React.Component{
    constructor(props){
        super()
        this.state={
            fields:{
                familycode:"",
                familyname:""
            },
            errors:{}
        }
        this.handleInputChange=this.handleInputChange.bind(this)
        this.toggleJoinFamilyForm=props.toggle
        this.toggleJoinFamilyForm=this.toggleJoinFamilyForm.bind(this)
        this.joinFamily=props.joinFamily
        this.joinFamily=this.joinFamily.bind(this)
    }

    postFamilyForm(){
        console.log("post")
        if (this.handleValidation()){
            this.joinFamily(this.state.fields.familycode, this.state.fields.familyname)
            this.props.toggle()
        } else{

        }
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Name
        if (!fields["familyname"]) {
            formIsValid = false;
            errors["familyname"] = "Veuillez renseigner un nom de famille";
        }

        if (fields["familyname"].length>255) {
            formIsValid = false;
            errors["familyname"] = "Le nom de famille n'est pas valide";
        }

        //Code
        if (!fields["familycode"]) {
            formIsValid = false;
            errors["familycode"] = "Veuillez renseigner un code";
        }
        
        if (fields["familycode"].length>8) {
            formIsValid = false;
            errors["familycode"] = "Le code n'est pas valide";
        }

        this.setState({errors: errors})
        return formIsValid;
    }

    handleInputChange(event){
        const eventtargetvalue = event.target.value
        const name = event.target.name
        this.setState(prevState => {
            return {fields: {...prevState.fields, [name]: eventtargetvalue}}
        })
    }

    render(){
        const { open } = this.props;
        return (
            <Modal backdropClassName="modalBackdrop" open={open} toggle={this.props.toggle}>
                <ModalHeader>Rejoindre une famille existante</ModalHeader>
                <ModalBody>
                    <Row>
                        <div className="p-3 mt-3 border rounded">
                            <h5>Renseigner le code d'invitation <span role="img" aria-label="cadenas">🔒</span></h5>
                            <span>Pour rejoindre la famille, renseigner le nom de la famille et le code d'invitation fournis par la personne qui vous invite, puis valider.</span>
                        </div>
                    </Row>
                    {this.state.errors[Object.keys(this.state.errors)[0]] &&<Alert theme="danger">{this.state.errors[Object.keys(this.state.errors)[0]]}</Alert>}
                    <Row>
                        <Form>
                            <InputGroup className="mb-2">
                                <InputGroupAddon type="prepend">
                                    <InputGroupText>Nom</InputGroupText>
                                </InputGroupAddon>
                                <FormInput name="familyname" placeholder="Nom de la famille" value={this.state.fields.familyname} invalid={!this.state.errors["familyname"]===false} onChange={this.handleInputChange}/> 
                            </InputGroup>
                            <InputGroup className="mb-2">
                                <InputGroupAddon type="prepend">
                                    <InputGroupText>Code</InputGroupText>
                                </InputGroupAddon>
                                <FormInput name="familycode" placeholder="Code à 6 lettres" value={this.state.fields.familycode} invalid={!this.state.errors["familycode"]===false} onChange={this.handleInputChange}/> 
                            </InputGroup>
                            <Row>
                                <Col><Button theme="success" onClick={()=>{this.postFamilyForm()}}> Valider </Button></Col>
                            </Row>
                    </Form>
                    </Row>
                </ModalBody>
            </Modal>
        );
    };
}

export default JoinFamilyForm