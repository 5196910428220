import React from 'react'
import { Alert, ButtonGroup, Container, Form, FormInput, FormGroup, InputGroup, FormTextarea, InputGroupAddon, InputGroupText, Button, CardHeader, Card, ListGroup, ListGroupItem, Row, Col } from "shards-react";
import StarRatings from 'react-star-ratings';
import { debounce } from 'lodash';
import { Checkmark, Close, Gift, Trash } from 'grommet-icons';

class Addwishform extends React.Component{
    constructor(props){
        super()
        this.state={
            isLoading:true,
            data:{},
            newwish:props.wish||{
                name:"",
                importance:1,
                comment:"",
                url:"",
                img:"",
                isPurchased:false,
                isSecret:props.self===false
            },
            /*
            newwish:{
                name:props.name||"",
                importance:props.importance||1,
                comment:props.comment||"",
                url:props.url||"",
                img:props.img||"",
                isPurchased:props.isPurchased||false,
                isSecret:props.isSecret||props.self===false
            },*/
            errors:{},
            self:props.self
        }
        this.handleClick=props.handleClick
        this.toggle=props.toggle
        this.getWishDetails=props.getWishDetails
        this.getDetails=debounce(this.getDetails,1000).bind(this)
        this.handleInputChange=this.handleInputChange.bind(this)
        this.handleURLChange=this.handleURLChange.bind(this)
        this.handleStarClick=this.handleStarClick.bind(this)
        this.putwish=props.putwish
        this.deletewish=props.deletewish
        this.getWishDetails=props.getWishDetails
        this.handleInputChange=this.handleInputChange.bind(this)
        //this.handleWebHook=this.handleWebHook.bind(this)
        this.handleStarClick=this.handleStarClick.bind(this)
        this.getWishDetails=props.getWishDetails
        this.getWishDetails=this.getWishDetails.bind(this)

    }

    handleValidation() {
        let fields = this.state.newwish;
        let errors = {};
        let formIsValid = true;

        //Name
        if (!fields["name"]) {
            formIsValid = false;
            errors["name"] = "Nom du cadeau vide";
        }

        //URL
        if (fields["url"].length>254) {
            formIsValid = false;
            errors["url"] = "URL du site d'achat trop longue";
        }

        //Comment
        if (fields["comment"].length>254) {
            formIsValid = false;
            errors["comment"] = "Commentaire trop long";
        }

        //Image URL
        if (fields["img"].length>254) {
            formIsValid = false;
            errors["img"] = "URL de l'image trop longue";
        }
        

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleURLChange(event){
        const eventtargetvalue = event.target.value
        const name = event.target.name
        this.setState(prevState => {
            return {newwish: {...prevState.newwish, [name]: eventtargetvalue}}
        })
        this.getDetails(eventtargetvalue)
    };


    handleInputChange(event){
        const eventtargetvalue = event.target.value
        const name = event.target.name
        this.setState(prevState => {
            return {newwish: {...prevState.newwish, [name]: eventtargetvalue}}
        })
    }

    handleStarClick(val){
        const eventtargetvalue = val
        this.setState(prevState => {
            return {newwish: {...prevState.newwish, importance: eventtargetvalue}}
        })
    }

    postwish(){
        if (this.handleValidation()) {
            this.handleClick(this.state.newwish)
            this.props.toggle()
        } else {
            
        }
    }

    editwish(){
        if (this.handleValidation()) {
            this.putwish(this.state.newwish,this.props.wish.id)
            this.props.toggle()
        } else {
            
        }
    }


    getDetails(value){
        this.getWishDetails(value)
        .then(data=>{
            console.log(data)
            if(!data.message){
                this.setState(prevState=>{
                    return {
                        newwish: {
                            ...prevState.newwish, 
                            name:data.data.title,
                            img:data.data.image
                        }
                    }
                })
            }
        })
    }

    render(){
        
        return(
            <Card>
                {this.props.editForm ?
                <CardHeader>
                    <h5>Modifier le cadeau</h5>
                </CardHeader>:
                <CardHeader>
                    <h5>Nouveau cadeau <span role="img">{this.state.self ? "🎁":"secret👻" }</span> </h5>
                </CardHeader>}
                <ListGroup >
                    <ListGroupItem>
                            <Form>
                                <FormGroup>
                                    {this.state.errors[Object.keys(this.state.errors)[0]] &&<Alert theme="danger">{this.state.errors[Object.keys(this.state.errors)[0]]}</Alert>}
                                    <InputGroup className="mb-2">
                                        <InputGroupAddon type="prepend">
                                            <InputGroupText>Nom du cadeau</InputGroupText>
                                        </InputGroupAddon>
                                        <FormInput name="name" id="#Wishname" placeholder="Nom du cadeau" value={this.state.newwish.name} invalid={!this.state.errors["name"]===false} onChange={this.handleInputChange}/>
                                    </InputGroup>

                                    
                                    <InputGroup className="mb-2">
                                        <InputGroupAddon type="prepend">
                                            <InputGroupText>URL</InputGroupText>
                                        </InputGroupAddon>
                                        <FormInput name="url" id="#Url" placeholder="Lien vers le site d'achat (Exemple : lien Amazon)" value={this.state.newwish.url} invalid={!this.state.errors["url"]===false} onChange={this.handleURLChange} />
                                    </InputGroup>

                                    <InputGroup className="mb-2">
                                        <InputGroupAddon type="prepend">
                                            <InputGroupText>Commentaire</InputGroupText>
                                        </InputGroupAddon>
                                        <FormTextarea name="comment" id="#Description" placeholder="Commentaire" value={this.state.newwish.comment} invalid={!this.state.errors["comment"]===false} onChange={this.handleInputChange} />
                                    </InputGroup>

                                    <InputGroup className="mb-2">
                                        <InputGroupAddon type="prepend">
                                            <InputGroupText>URL</InputGroupText>
                                        </InputGroupAddon>
                                        <FormInput name="img" id="#Wishnimg" placeholder="Lien vers l'image" value={this.state.newwish.img} invalid={!this.state.errors["img"]===false} onChange={this.handleInputChange}/>
                                    </InputGroup>

                                    <InputGroup className="mb-2">
                                        <InputGroupAddon type="prepend">
                                            <InputGroupText>Priorité</InputGroupText>
                                        </InputGroupAddon>
                                        <div width="100%" className="mb2 border ">
                                            <StarRatings 
                                                rating={this.state.newwish.importance}
                                                starRatedColor="rgb(255, 180, 0)"
                                                starHoverColor="rgb(255, 180, 0)"
                                                changeRating={this.handleStarClick}
                                                numberOfStars={5}
                                                name='rating'
                                            />
                                        </div>
                                    </InputGroup>
                                </FormGroup>
                                {this.props.editForm ?
                                    <ButtonGroup>
                                        <Button size="sm" outline theme="secondary" onClick={()=>{this.props.toggle()}}><Close color='plain' size='small'/>Annuler</Button>
                                        <Button size="sm" outline theme="danger" onClick={()=>{this.deletewish(this.props.wish.id);this.props.toggle()}}> <Trash color='red' size='small'/> Supprimer </Button>
                                        <Button outline theme="success" onClick={()=>{this.editwish()}}> <Checkmark color='green' size='small'/> Valider </Button>
                                    </ButtonGroup>:
                                <ButtonGroup>
                                    <Button outline theme="secondary" onClick={()=>{this.props.toggle()}}><Close color='plain' size='small'/> Annuler</Button>
                                    <Button outline block theme="success" onClick={()=>{this.postwish()}}><Gift color='green'/> Ajouter à {this.state.self ? "ma":"la" } liste  </Button>
                                </ButtonGroup>
                                }

                            </Form>
                    </ListGroupItem>
                </ListGroup>
            </Card>
        )
    };
}

export default Addwishform