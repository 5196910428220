import React from "react";
import { Container } from "shards-react";

const Errors = () => (
  <Container fluid className="main-content-container px-4 pb-4">
    <div className="error">
      <div className="error__content">
        <h2>Hello!</h2>
        <h3>La liste est vide <span role="img" aria-label="cadeau">🎁</span></h3>
        <p>...mais tu peux y ajouter un cadeau!</p>
      </div>
    </div>
  </Container>
);

export default Errors;
