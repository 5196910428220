import React from 'react'
import User from './User'
import {CardHeader, CardBody, Container, Row, Col, Button, Card, Modal, ModalBody, ModalHeader} from "shards-react";
import JoinFamilyForm from './JoinFamilyForm';
import CreateFamilyForm from './CreateFamilyForm';


class Family extends React.Component{
    constructor(props){
        super()
        this.state={
            users:props.users,
            familyName:props.name,
            familyCode:props.code,
            showFamilyJoinForm:false,
            showFamilyCode:false,
            showFamilyCreation:false
        }
        this.nav=props.nav
        this.toggleFamilyCode=this.toggleFamilyCode.bind(this)
    }

    toggleFamilyCode(){
        this.setState({showFamilyCode:!this.state.showFamilyCode});
    }

    render(){
        const userarray = this.state.users
        .sort((item1,item2) => {
            if(item1.name.toLowerCase() < item2.name.toLowerCase()) return -1;
            if(item1.name.toLowerCase() > item2.name.toLowerCase()) return 1;
        })
        .map(item => <User key={item.uid} name={item.name} id={item.uid} status={item.status} img={item.img} handleClick={this.nav} />)
        
        return(
            <Container fluid className="main-content-container px-4">
                <Card className="mx-2 mb-5">
                    <CardHeader className="page-header py-4">
                        <Col><h1>Famille {this.state.familyName}</h1></Col>
                        <Col>
                            
                        </Col>
                        <Col>
                            <Button onClick={this.toggleFamilyCode}>Inviter des participants <span role="img" aria-label="invité">🎅</span></Button>
                        </Col>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            {userarray}
                        </Row>
                        <Container>
                            {(userarray.length===0) &&
                            <Container fluid className="main-content-container px-4 pb-4">
                            <Row>
                            <div className="error">
                                <div className="error__content">
                                <h2>Oh!</h2>
                                <h3>Il n'y a personne ici <span role="img" aria-label="triste">😿</span></h3>
                                </div>
                            </div>
                            </Row>
                        </Container>
                        }
                        </Container>
                        {this.state.showFamilyJoinForm && 
                            <JoinFamilyForm open={this.state.showFamilyJoinForm} toggle={this.toggleJoinFamilyForm} joinFamily={this.joinFamily}></JoinFamilyForm>
                        }
                        {this.state.showFamilyCreation && 
                            <CreateFamilyForm createFamily={this.createFamily} open={this.state.showFamilyCreation} toggle={this.toggleFamilyCreation}></CreateFamilyForm>
                        }
                        <Modal backdropClassName="modalBackdrop" open={this.state.showFamilyCode} toggle={this.toggleFamilyCode}>
                            <ModalHeader>Inviter des participants</ModalHeader>
                            <ModalBody>
                                <Row>
                                    <div className="p-3 mt-3 border rounded">
                                        <h5>Les informations à communiquer</h5>
                                        <p>Pour ajouter quelqu'un à votre cercle familial, transmettez lui les informations suivantes :</p>
                                        <p>Nom de famille : <b>{this.state.familyName}</b></p>
                                        <p>Code de famille : <b>{this.state.familyCode}</b></p>
                                    </div>
                                </Row>
                            </ModalBody>
                        </Modal>
                    </CardBody>
                    
                </Card>
                    
            </Container>
            
        )
    };
}

export default Family