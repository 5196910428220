import React, {useState} from 'react';
import { createBrowserRouter, BrowserRouter as Router, RouterProvider, Route, Routes, Link } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Home from './Home'
import ForgotPassword from './ForgotPassword'
import ChangePassword, {loader as changePasswordLoader} from './ChangePassword'

export default App =>{
  const browserHistory = createBrowserHistory();

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      children: [
      ],
    },
    {
      path: "forgotpassword",
      element: <ForgotPassword />
    },
    {
      path: "/reset/:userId/:hash",
      element: <ChangePassword />,
      loader: changePasswordLoader,
    },
  ]);

  return(
    <RouterProvider router={router} />
  )
}
