import React from "react";
import { Alert } from "shards-react";


export default class Appalert extends React.Component {
  constructor(props) {
    super(props);
    this.dismiss = props.dismiss.bind(this)
  }

  render() {
    const {visible,message,theme}=this.props.alert
    return (
      <Alert dismissible={this.dismiss} open={visible} theme={theme}>
        {message}
      </Alert>
    );
  }
}