import React from 'react'
import { Button, Container, Modal, ModalBody, ModalHeader,FormInput, Form, FormGroup, Row } from "shards-react";
import Appalert from './Appalert'
import {Link} from "react-router-dom"
import userservice from './services/userservice';

class LoginForm extends React.Component{
    constructor(props){
        super()
        this.state={
            open:true,
            login:"",
            password:"",
            register:false,
            familycode:"",
            currentAlert:{
                message:"message",
                theme:"success",
                visible:false
            },
            email:"",
            familyname:""
        }
        this.signin=this.signin.bind(this)
        this.register= this.register.bind(this)
        this.handleChangePass=this.handleChangePass.bind(this)
        this.handleChangeUserName=this.handleChangeUserName.bind(this)
        this.handleChangeFamilyCode=this.handleChangeFamilyCode.bind(this)
        this.handleChangeUserFamilyName=this.handleChangeUserFamilyName.bind(this)
        this.handleChangeUserEmail=this.handleChangeUserEmail.bind(this)
        this.handleClick=this.handleClick.bind(this)
        this.showAlert = this.showAlert.bind(this)
        this.dismiss = this.dismiss.bind(this)
    }

    showAlert(message,theme){
        this.setState({
          currentAlert:{
            message:message,
            theme:theme,
            visible:true
          }
        })
    }

    dismiss() {
        this.setState(prevState=>{ 
          return {
            currentAlert:{
              ...prevState.currentAlert,
              visible: false 
            }
          }
        });
    }

    signin(login,password){
        userservice.signin(login,password)
        .then(response => response.json())
        .then((data) => {
            if(data.message){
                this.showAlert(data.message,"danger")
                this.props.refreshuser(false)
            }
            else{
            this.props.refreshuser(true)
            }  
        })
        .catch(err=>{
            this.props.refreshuser(false)
            this.showAlert(err.message,"danger")
        })
    }
    
      register(username,password,email ){
        const q = "/api/register"
        return fetch(q,{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "name":username,
                "password":password,
                "email":email
            })
        })
        .then(response => response.json())
        .then((data) => {
            console.log(data)
            if(data.message){
                this.showAlert(data.message,"danger")
                this.props.refreshuser(false)
            }
            else{
                this.showAlert("Utilisateur créé","success")
                this.setState({register:false})
            }  
        })
        .catch(err=>{
            console.log(err)
            this.props.refreshuser(false)
            this.showAlert(err.message,"danger")
        }) 
      }

    handleClick(){
        this.setState({register:!this.state.register})
    }

    handleChangePass(event){
        this.setState({password:event.target.value})
    }

    handleChangeUserName(event){
        this.setState({login:event.target.value})
    }

    handleChangeFamilyCode(event){
        this.setState({familycode:event.target.value})
    }

    handleChangeUserEmail(event){
        this.setState({email:event.target.value})
    }

    handleChangeUserFamilyName(event){
        this.setState({familyname:event.target.value})
    }

    render(){
        const { open } = this.state;
        return (
            <Modal backdropClassName="modalBackdrop" centered={true} open={open} toggle={this.toggle}>
                <ModalHeader>{this.state.register ? "Nouvel utilisateur":"Connexion"}</ModalHeader>
                <ModalBody>
                    <Appalert alert={this.state.currentAlert} dismiss={this.dismiss}></Appalert>
                        <Form>
                        <FormGroup>
                            {this.state.register &&
                                <div>
                                    <label htmlFor="#username">Adresse email</label>
                                    <FormInput id="#email" placeholder="adresse email" onChange={this.handleChangeUserEmail} />
                                </div>
                            }
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="#username">Login</label>
                            <FormInput id="#username" placeholder="nom d'utilisateur ou adresse mail" onChange={this.handleChangeUserName} />
                            <label htmlFor="#password">Mot de passe</label>
                            <FormInput type="password" id="#password" placeholder="Mot de passe" onChange={this.handleChangePass} />
                        </FormGroup>
                        {!this.state.register &&
                            <Button onClick={()=>this.signin(this.state.login,this.state.password)}>Se connecter <span>➡</span></Button>
                        }
                        {this.state.register &&
                            <Button theme="secondary" onClick={()=>this.register(this.state.login,this.state.password,this.state.email)}>S'enregistrer <span>➡</span></Button>
                        }
                        <Button theme="light" onClick={this.handleClick}>{this.state.register ? "Retour":"Créer un profil"}</Button>
                        </Form>
                    <Container>
                        <Link  target="_blank" rel="noopener noreferrer" to="/forgotpassword">Mot de passe oublié ?</Link>
                    </Container>
                </ModalBody>
            </Modal>
        );
    };
}

export default LoginForm